/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FacilitiesSection = () => {
  // Define your image URLs
  const imageUrls = [
    "/assets/images/work/1.jpg",
    "/assets/images/work/2.jpg",
    "/assets/images/work/3.jpg",
    "/assets/images/work/4.jpg",
    "/assets/images/work/5.jpg",
    "/assets/images/work/6.jpg",
    "/assets/images/work/7.jpg",
    "/assets/images/work/8.jpg",
  ];

  // Slick settings for the carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };

  return (
    <section id="facilities" className="page-section">
      <div className="container text-center">
        <div className="heading">
          <h2>Our Facilities</h2>
          <p>
            We pride ourselves on providing a welcoming and comfortable environment
            designed to meet the unique healthcare needs of women. Our facilities are
            equipped with the latest technology, and our team is committed to
            delivering personalized care in every aspect of women's health.
          </p>
        </div>

        {/* Render the image carousel */}
        <Slider {...settings}>
          {imageUrls.map((imageUrl, index) => (
            <div key={index} className="imgfacilities text-center">
              <a href={process.env.PUBLIC_URL + imageUrl} className="fancybox">
                <img
                  className="img-responsive"
                  src={process.env.PUBLIC_URL + imageUrl}
                  alt={`Image ${index + 1}`}
                />
                <div className="overlay">
                  <span></span>
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default FacilitiesSection;
