import React from "react";

const Services = () => {
  return (
    <>
      <section id="services" className="page-section colord">
        <div className="container">
          <div className="heading text-center">
            {/* <!-- Heading --> */}
            <h2>Our Services</h2>
            {/* <!-- <p>At lorem Ipsum available, but the majority have suffered alteration in some form by injected humour.</p> --> */}
          </div>

          <div
            className="community-images"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignContent: "center",
            }}
          >
            {/* // <!-- item --> */}
            <div className="col-md1-3 text-center c1">
              <img
                src="assets/images/services/pelvic-exam.png"
                style={{ width: "30%" }}
                alt="logo"
              />
              {/* <!-- <i className="fa fa-life-ring fa-2x circle"></i> --> */}
              <h3>
                <span className="id-color">1. Routine Gynecological Check-ups</span>
              </h3>
              <p>
                • Annual check-ups for women to ensure overall reproductive health.{" "}
                <br />• Pap smears, breast exams, and other preventive screenings.
              </p>
            </div>
            {/* <!-- end: --> */}

            {/* <!-- item --> */}
            <div className="col-md1-3 text-center c2">
              <img
                src="assets/images/services/motherhood.png"
                style={{ width: "30%" }}
                alt="logo"
              />
              {/* <!-- <i className="fa fa-plus-square fa-2x circle"></i> --> */}
              <h3>
                <span className="id-color">2. Pregnancy Care</span>
              </h3>
              <p>
                • Prenatal care for expecting mothers, including regular check-ups
                and monitoring. <br />• Ultrasound services for fetal development
                tracking.
              </p>
            </div>
            {/* <!-- end: --> */}

            {/* <!-- item --> */}
            <div className="col-md1-3 text-center c1">
              <img
                src="assets/images/services/happy.png"
                style={{ width: "30%" }}
                alt="logo"
              />
              {/* <!-- <i className="fa fa-female fa-2x circle"></i> --> */}
              <h3>
                <span className="id-color">3. Family Planning</span>
              </h3>
              <p>
                • Contraception counseling and methods. <br />• Fertility evaluations
                for couples trying to conceive.
              </p>
            </div>
            {/* <!-- end: --> */}

            {/* <!-- item --> */}
            <div className="col-md1-3 text-center c2">
              <img
                src="assets/images/services/menstrual-cycle.png"
                style={{ width: "30%" }}
                alt="logo"
              />

              {/* <!-- <i className="fa fa-child fa-2x circle"></i> --> */}
              <h3>
                <span className="id-color">4. Menstrual Health</span>
              </h3>
              <p>
                • Treatment for irregular periods, heavy bleeding, or painful
                menstruation. <br />• Management of menstrual disorders.
              </p>
            </div>
            {/* <!-- end:--> */}

            {/* <!-- item --> */}
            <div className="col-md1-3 text-center c1">
              <img
                src="assets/images/services/menopause.png"
                style={{ width: "30%" }}
                alt="logo"
              />
              {/* <!-- <i className="fa fa-life-ring fa-2x circle"></i> --> */}
              <h3>
                <span className="id-color">5. Menopause Management</span>
              </h3>
              <p>
                • Guidance and support for women experiencing menopause. <br />•
                Hormone replacement therapy options.
              </p>
            </div>
            {/* <!-- end: --> */}

            {/* <!-- item --> */}
            <div className="col-md1-3 text-center c2">
              <img
                src="assets/images/services/surgery.png"
                style={{ width: "30%" }}
                alt="logo"
              />
              {/* <!-- <i className="fa fa-plus-square fa-2x circle"></i> --> */}
              <h3>
                <span className="id-color">6. Gynecological Surgeries</span>
              </h3>
              <p>
                • Surgical interventions for conditions like fibroids, ovarian cysts,
                or endometriosis. <br />• Hysterectomy or other procedures as needed.
              </p>
            </div>
            {/* <!-- end: --> */}

            {/* <!-- item --> */}
            <div className="col-md1-3 text-center c1">
              <img
                src="assets/images/services/std.png"
                style={{ width: "30%" }}
                alt="logo"
              />
              {/* <!-- <i className="fa fa-female fa-2x circle"></i> --> */}
              <h3>
                <span className="id-color">7. STI/STD Testing and Treatment</span>
              </h3>
              <p>
                • Confidential testing for sexually transmitted infections. <br />•
                Treatment plans for infections or diseases.
              </p>
            </div>
            {/* <!-- end: --> */}

            {/* <!-- item --> */}
            <div className="col-md1-3 text-center c2">
              <img
                src="assets/images/services/meditation.png"
                style={{ width: "30%" }}
                alt="logo"
              />
              {/* <!-- <i className="fa fa-child fa-2x circle"></i> --> */}
              <h3>
                <span className="id-color">8. Women’s Wellness Programs</span>
              </h3>
              <p>
                • Health and wellness education programs. <br />• Nutrition and
                fitness counseling.
              </p>
            </div>
            {/* <!-- end:--> */}

            {/* <!-- item --/> */}
            <div className="col-md1-3 text-center c1">
              <img
                src="assets/images/services/pelvis.png"
                style={{ width: "30%" }}
                alt="logo"
              />
              {/* <!-- <i className="fa fa-life-ring fa-2x circle"></i> --> */}
              <h3>
                <span className="id-color">9. Pelvic Health</span>
              </h3>
              <p>
                • Evaluation and treatment for pelvic floor disorders. <br />•
                Incontinence management.
              </p>
            </div>
            {/* <!-- end: --> */}

            {/* <!-- item --> */}
            <div className="col-md1-3 text-center c2">
              <img
                src="assets/images/services/health-care.png"
                style={{ width: "30%" }}
                alt="logo"
              />
              {/* <!-- <i className="fa fa-plus-square fa-2x circle"></i> --> */}
              <h3>
                <span className="id-color">10. Adolescent Gynecology</span>
              </h3>
              <p>
                • Specialized care for teenage girls. <br />• Education on
                reproductive health for young women.
              </p>
            </div>
            {/* <!-- end: --> */}

            {/* <!-- item --> */}
            <div className="col-md1-3 text-center c1">
              <img
                src="assets/images/services/emergency-call.png"
                style={{ width: "30%" }}
                alt="logo"
              />
              {/* <!-- <i className="fa fa-female fa-2x circle"></i> --> */}
              <h3>
                <span className="id-color">11. Telemedicine Services</span>
              </h3>
              <p>
                • Virtual consultations for certain gynecological concerns. <br />•
                Remote monitoring for certain conditions.
              </p>
            </div>
            {/* <!-- end: --> */}

            {/* <!-- item --> */}
            <div className="col-md1-3 text-center c2">
              <img
                src="assets/images/services/customer-support.png"
                style={{ width: "30%" }}
                alt="logo"
              />
              {/* <!-- <i className="fa fa-child fa-2x circle"></i> --> */}
              <h3>
                <span className="id-color">12. Support Groups</span>
              </h3>
              <p>
                • Emotional and psychological support groups for women dealing with
                specific health issues. <br />• Educational seminars on women's
                health topics.
              </p>
            </div>
            {/* <!-- end:--> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
