import React from "react";

const Footer = () => {
  return (
    <>
      <section id="Contact" className="contact-parlex">
        <div className="parlex-back">
          <div className="container">
            <div className="row">
              <div className="heading text-center">
                <h2>Contact Us</h2>
                <p>
                  We're here for you. If you have any questions, concerns, or would
                  like to schedule an appointment with our experienced team of
                  gynaecologists, feel free to reach out. Your well-being is our
                  priority, and we're dedicated to providing personalized care
                  tailored to your needs.
                </p>
              </div>
            </div>
            <div className="row mrgn30" style={{ textAlign: "center" }}>
              <div className="col-sm-6" style={{ border: "1px solid" }}>
                <h3>ADDRESS: RAWALPINDI/ISLAMABAD</h3>
                <address>
                  Hearts international hospital, <br />
                  189-A Sir Syed Road, <br />
                  Saddar, Rawalpindi, Punjab 46000
                </address>
                <h4>Phone:</h4>
                <address>
                  <a href="tel: (051)-4252008">(051)-4252008</a>
                </address>
              </div>

              <div className="col-sm-6" style={{ border: "1px solid" }}>
                <h3>ADDRESS: KOTLI AJ&K</h3>
                <address>
                  Anwar Memorial Hospital, <br />
                  Post Office St, Kotli, <br />
                  Azad Jammu and Kashmir
                </address>
                <h4>Phone:</h4>
                <address>
                  <a href="tel: (058264)-43610">(058264)-43610</a>
                </address>
              </div>
            </div>
            <p className="text-muted">All Rights Reserved &copy; Dr.Mariyam</p>
          </div>
        </div>
        <a href="#top" className="topHome" style={{ color: "#000" }}>
          <i className="fa fa-chevron-up fa-2x"></i>
        </a>
      </section>
    </>
  );
};

export default Footer;
