/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
const Header = () => {
  return (
    <>
      <header className="header">
        <div className="container">
          <nav className="navbar navbar-inverse" role="navigation">
            <div className="navbar-header">
              <button
                type="button"
                id="nav-toggle"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#main-nav"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a
                href="#home"
                className="navbar-brand scroll-top logo  animated bounceInLeft"
              >
                <b>Dr. MARIYAM</b>
              </a>
            </div>
            {/* <!--/.navbar-header--> */}
            <div id="main-nav" className="collapse navbar-collapse">
              <ul className="nav navbar-nav" id="mainNav">
                <li className="active" id="firstLink">
                  <a href="#home" className="scroll-link">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#services" className="scroll-link">
                    Services
                  </a>
                </li>
                <li>
                  <a href="#facilities" className="scroll-link">
                    Facilities
                  </a>
                </li>
                <li>
                  <a href="#Contact" className="scroll-link">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            {/* <!--/.navbar-collapse--> */}
          </nav>
          {/* <!--/.navbar--> */}
        </div>
        {/* <!--/.container--> */}
      </header>
    </>
  );
};

export default Header;
