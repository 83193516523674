import React from "react";
import { ReactTyped } from "react-typed";
import "./YourComponent.css"; // Import your component-specific stylesheet

const HomeSection = () => {
  return (
    <section id="home">
      <div className="banner-container">
        <img
          src="/assets/images/banner-bg.jpg"
          alt="Dr. Mariyam's Gynaecology Practice Banner"
        />

        <div className="container banner-content">
          <div className="hero-text animated fadeInDownBig">
            <h1 className="responsive-headline">
              <div className="typed-container">
                <ReactTyped
                  strings={["Welcome to Dr. Mariyam's Gynaecology Practice"]}
                  typeSpeed={40}
                  backSpeed={80}
                />
                <br />
                <br />
                <ReactTyped
                  strings={[
                    "Embrace Exceptional Women's Health Care with Dr. Mariyam",
                    "We care about your health and well-being",
                    "Expertise in women's reproductive health",
                    "Providing compassionate and personalized care",
                  ]}
                  typeSpeed={40}
                  // backSpeed={80}
                  startDelay={1500}
                  loop
                />
              </div>
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSection;
