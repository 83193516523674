import React from "react";
import FacilitiesSection from "./Facilities";
import Services from "./Services";
import HomeSection from "./HomeSection";

const Home = () => {
  return (
    <>
      <HomeSection />
      <Services />
      <FacilitiesSection />
    </>
  );
};

export default Home;
